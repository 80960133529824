import { motion } from "framer-motion"
import { useRef } from "react";

const variants = {
    hidden: { opacity: 0},
    visible: { opacity: 1, transition: { type: "tween", duration: 0.5}},
}

export const Distributions = () => {
    function show(dist) {

        var btn = document.getElementById(dist)!;
        let hidden = btn.hasAttribute('hidden')
    
        if (hidden === true) {
          btn.removeAttribute('hidden');
          btn.setAttribute('animate','visible')
          btn.setAttribute('initial','hidden')
        } else {
          btn.setAttribute('hidden', 'hidden');
          btn.removeAttribute('animate')
          btn.removeAttribute('initial')
        }
      }

    return (
        <main>
            <div className='container flex-col flex p-4 bg-gray-800 rounded-lg'>
                <button id='ubuntu' onClick={() => show('ubuntud')} className='box-border grid-rows-1 w-full p-6 hover:bg-gray-700'>
                    <div className="flex align-center">
                        <motion.div className="float-left">
                            <img className='w-1/2' src='images/ubuntu-logo.png' alt='ubuntu'></img>
                            <div className='w-1/2 text-center p-4 font-bold'>Ubuntu</div>
                        </motion.div>
                        <motion.div variants={variants} hidden initial='hidden' whileInView='visible' id="ubuntud" className='w-full float-left text-left'>
                            <ul>
                            <li>
                                <p>
                                <strong className="text-orange-500"><a className="hover:text-orange-700 hover:font-bold" href="https://ubuntu.com" target="_blank">Ubuntu</a></strong> est l'une des distributions Linux les plus populaires et largement utilisées dans le monde. Elle est connue pour sa simplicité d'utilisation, sa stabilité et sa large panelle de fonctionnalités. Lancé en 2004 par Canonical Ltd., Ubuntu est basée sur Debian. Il est distribué sous une licence open source.
                                </p>
                            </li>
                            <br></br>
                            <li>
                                <strong><u><p>Voici quelques points importants d'Ubuntu :</p></u></strong>
                                <br></br>
                                <p><strong className="text-orange-500">Simple d’utilisation pour les débutants :</strong> Ubuntu est réputée pour son interface utilisateur simple et épuré, ce qui en fait un excellent choix pour ceux qui souhaitent passer sur Linux.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-orange-500">Cycle de développement régulier :</strong> Ubuntu suit un cycle de développement régulier avec des versions stables publiées tous les six mois. Il existe également des versions LTS (Long Term Support) publiées tous les deux ans, offrant un support à long terme pour les mises à jour et les correctifs de sécurité.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-orange-500">Vaste communauté active :</strong> Ubuntu bénéficie d'une communauté active de développeurs, d'utilisateurs et de contributeurs. Ainsi cela signifie qu'il existe de nombreuses ressources en ligne, forums de discussion et documentation pour aider les utilisateurs en cas de problème.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-orange-500">Logiciels inclus et centre de logiciels :</strong> Ubuntu est livré avec une gamme de logiciels préinstallés, y compris des applications de productivité, des outils multimédias, des navigateurs web, etc. De plus, le centre de logiciels Ubuntu permet aux utilisateurs de rechercher, installer et mettre à jour facilement des applications supplémentaires, il est semblable au store de Windows.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-orange-500">Sécurité et confidentialité :</strong> Ubuntu met l'accent sur la sécurité et la confidentialité. Il fournit des mises à jour régulières pour corriger les failles de sécurité tout en respectant la vie privée des utilisateurs.</p>
                            </li>
                            <br></br>
                            <li>
                                <p>Dans l'ensemble, <strong className="text-orange-500">Ubuntu</strong> est une distribution Linux robuste, adaptée à un large échantillon d'utilisateurs, des débutants aux professionnels en informatique.</p>
                            </li>
                            </ul>
                        </motion.div>
                    </div>
                        
                </button>
                

                <button id='debian' onClick={() => show('debiand')} className='box-border grid-rows-1 w-full p-8 hover:bg-gray-700'>
                    <div className="flex align-center">
                        <motion.div className="float-left">
                            <img className='w-1/2' src='images/debian-logo.png' alt='debian'></img>
                            <div className='w-1/2 text-center p-4 font-bold'>Debian</div>
                        </motion.div>
                        <motion.div variants={variants} hidden initial='hidden' whileInView='visible' id="debiand" className='w-full float-left text-left'>
                            <ul>
                            <li>
                                <p>
                                <strong className="text-red-400"><a className="hover:text-red-600 hover:font-bold" href="https://www.debian.org" target="_blank">Debian</a></strong> est l'une des distributions Linux les plus anciennes et respectées. Elle est lancée en 1993, elle est développée par une communauté de volontaires à travers le monde.
                                </p>
                            </li>
                            <br></br>
                            <li>
                                <strong><u><p>Voici une brève présentation de Debian :</p></u></strong>
                                <br></br>
                                <p><strong className="text-red-400">Stabilité et Fiabilité :</strong> Debian est réputée pour sa stabilité et sa fiabilité. Elle privilégie la qualité et la cohérence du système, ce qui en fait un choix important pour les serveurs et les environnements de développement professionnels.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-red-400">Philosophie du Logiciel Libre</strong> Debian est une des distributions de Linux les plus libres. Tous les logiciels inclus dans Debian respectent les critères stricts de liberté définis par la licence <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://www.gnu.org/licenses/licenses.fr.html" target="_blank">GNU GPL</a> et autres licences libres.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-red-400">Nombreuses Architectures</strong> Debian prend en charge un large panel d'architectures matérielles, ce qui en fait un choix polyvalent et léger pour les différents types de matériels, des ordinateurs personnels aux serveurs, en passant par les systèmes embarqués.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-red-400">Versions Stables et Testing</strong> Debian propose différentes branches de son système, notamment la branche stable qui est adaptée à un usage général, et la branche testing qui est plus orientée vers les utilisateurs avancés et le développement.</p>
                            </li>
                            </ul>
                        </motion.div>
                    </div>
                        
                </button>

                <button id='arch' onClick={() => show('archd')} className='box-border grid-rows-1 w-full p-12 hover:bg-gray-700'>
                    <div className="flex align-center">
                        <motion.div className="float-left">
                            <img className='w-1/2' src='images/arch-logo.png' alt='arch'></img>
                            <div className='w-1/2 text-center p-4 font-bold'>Arch</div>
                        </motion.div>
                        <motion.div variants={variants} hidden initial='hidden' whileInView='visible' id="archd" className='w-full float-left px-8 text-left'>
                            <ul>
                            <li>
                                <p>
                                <strong className="text-blue-400"><a className="hover:text-blue-600 hover:font-bold" href="https://archlinux.org" target="_blank">Arch Linux</a></strong> est une distribution Linux légère et flexible, initialement créée par Judd Vinet en 2002.
                                </p>
                            </li>
                            <br></br>
                            <li>
                                <strong><u><p>Voici un aperçu de ses caractéristiques principales :</p></u></strong>
                                <br></br>
                                <p><strong className="text-blue-400">Simplicité et Minimalisme :</strong> Arch Linux suit le principe de KISS (Keep It Simple, Stupid), ce qui signifie qu'elle privilégie la simplicité et la clarté du système pour simplifier au maximum l’utilisation de l’utilisateur. Elle offre une base minimale et laisse aux utilisateurs la liberté de personnaliser leur environnement selon leurs besoins et leur désir.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-blue-400">Rolling Release :</strong> Arch Linux suit le modèle de publication continue (rolling release), ce qui signifie que les mises à jour logicielles sont publiées au fur et à mesure de leur disponibilité, offrant ainsi un accès rapide aux dernières versions des logiciels permettant une performance toujours accrue du système.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-blue-400">Arch User Repository (AUR) :</strong> L'un des points forts d'Arch Linux est l'<a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://wiki.archlinux.org" target="_blank">Arch User Repository</a>, une vaste collection de paquets logiciels maintenus par la communauté. Les utilisateurs peuvent facilement installer des logiciels tiers à partir de l'AUR, ce qui offre une grande flexibilité dans le choix des logiciels et l’utilisation du système.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-blue-400">Documentation Complète :</strong> Arch Linux dispose d'une documentation exhaustive, connue sous le nom de <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://aur.archlinux.org" target="_blank">ArchWiki</a>, qui fournit des instructions détaillées sur l'installation, la configuration et l'utilisation de la distribution. La documentation est maintenue par la communauté et est constamment mise à jour. Ce qui facilite l’usage du système pour un débutant en informatique.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-blue-400">Personnalisation et Contrôle total :</strong> En raison de sa nature minimaliste, Arch Linux permet aux utilisateurs d'avoir un contrôle total sur leur système. Les utilisateurs peuvent installer et configurer uniquement les logiciels et les fonctionnalités dont ils ont besoin, ce qui en fait un choix populaire parmi les utilisateurs connaisseur et les passionnés de Linux.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-blue-400">Communauté Active :</strong> Arch Linux bénéficie d'une communauté active et passionnée de développeurs et d'utilisateurs qui contribuent au développement, au support et à l'amélioration continue de la distribution.</p>
                            </li>
                            <br></br>
                            <li>
                                <p>En résumé, <strong className="text-blue-400">Arch Linux</strong> est une distribution Linux légère, minimaliste et hautement personnalisable, adaptée aux utilisateurs plutôt expérimentés qui recherchent un contrôle total sur leur système et une approche DIY et personnalisable de l'informatique.</p>
                            </li>
                            </ul>
                        </motion.div>
                    </div>
                        
                </button>

                <button id='kali' onClick={() => show('kalid')} className='box-border grid-rows-1 w-full p-10 hover:bg-gray-700'>
                    <div className="flex align-center">
                        <motion.div className="float-left">
                            <img className='w-1/2' src='images/kali-logo.png' alt='kali'></img>
                            <div className='w-1/2 text-center p-4 font-bold'>Kali</div>
                        </motion.div>
                        <motion.div variants={variants} hidden initial='hidden' whileInView='visible' id="kalid" className='w-full float-left px-10 text-left'>
                            <ul>
                            <li>
                                <p>
                                <strong className="text-sky-500"><a className="hover:text-sky-600 hover:font-bold" href="https://www.kali.org" target="_blank">Kali Linux</a></strong> est une distribution Linux spécialisée conçue principalement pour les tests de sécurité et la penetration testing (tests de pénétration).
                                </p>
                            </li>
                            <br></br>
                            <li>
                                <strong><u><p>Voici un aperçu de ses caractéristiques principales de ce système :</p></u></strong>
                                <br></br>
                                <p><strong className="text-sky-500">Orientée dans le domaine de la cyber-sécurité :</strong> Kali Linux est basée sur Debian, ce qui signifie qu'elle bénéficie de la stabilité et de la fiabilité de cette distribution Linux, en étant adaptée aux besoins spécifiques de la sécurité informatique.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-sky-500">Rolling Release :</strong> Tout comme Arch Linux, Kali Linux suit un modèle de publication continue (rolling release), ce qui permet aux utilisateurs d'accéder aux dernières versions des outils de sécurité dès leur disponibilité, ce qui permet une toujours d’avoir les meilleures performances.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-sky-500">Documentation Complète :</strong> Kali Linux dispose d'une <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://www.kali.org/docs/" target="_blank">documentation</a> exhaustive qui couvre l'installation, la configuration et l'utilisation des outils de sécurité inclus dans la distribution. Cette documentation est accessible en ligne et est constamment mise à jour.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-sky-500">Support Matériel Étendu :</strong> Kali Linux prend en charge une large gamme de matériel, y compris les ordinateurs portables, les ordinateurs de bureau et les systèmes embarqués, ce qui en fait un choix polyvalent pour les tests de sécurité sur différents types de plateformes.</p>
                            </li>
                            <br></br>
                            <li>
                                <p><strong className="text-sky-500">Distribution Live :</strong> Kali Linux est disponible sous forme de distribution live, ce qui signifie qu'elle peut être utilisée sans installation sur le disque dur. Cela permet aux utilisateurs de l'exécuter à partir d'une clé USB ou d'un DVD sans modifier leur système d'exploitation principal de l’outils utilisé.</p>
                            </li>
                            <br></br>
                            <li>
                                <p>En résumé, <strong className="text-sky-500">Kali Linux</strong> est une distribution Linux spécialisée principalement dans la sécurité informatique, offrant une large gamme d'outils de penetration testing et de forensic. Elle est souvent utilisée par les professionnels de la sécurité et les chercheurs en sécurité pour évaluer et renforcer la sécurité des systèmes informatiques</p>
                            </li>
                            </ul>
                        </motion.div>
                    </div>
                        
                </button>

            </div>
        
        </main>
    )



}