export const Home = () => {
  function score() {
    let score = 0;

    const answer_1 = document.getElementById("fname") as HTMLInputElement;
    
    if (answer_1.value === "Unix") {
      score += 1

    }else if (answer_1.value === "unix"){
      score += 1

    }else {
      score += 0
    }

    const answer_2 = document.getElementById("lname") as HTMLInputElement;
    
    if (answer_2.value === "Kali") {
      score += 1

    }else if (answer_2.value === "kali"){
      score += 1

    }else if (answer_2.value === "kali linux"){
      score += 1

    }else if (answer_2.value === "Kali linux"){
      score += 1

    }else if (answer_2.value === "Kali Linux"){
      score += 1

    }else {
      score += 0
    }

    if (score === 2){
      alert('Bravo, tu as réussi le quizz !!')

    }else {
      alert('Recommence, tu y arriveras peut-être')
    }
  }

  return (
    <main className="h-screen">
      <u><strong><h1 className="text-3xl text-center ">Bienvenue sur la page d'accueil, ce site sert de support pour un exposé sur Linux</h1></strong></u>
      <br></br>
      <div className="bg-gray-800 rounded-lg mt-10 text-[16px]">
        <div className="quiz-container w-4/5 m-auto m-0 text-center">
          <div className="text-2xl text-center py-10"><u>Petit Quiz !</u> <u className="text-xs">(à faire après avoir lu les autres pages)</u></div>
          <div>
            <form onSubmit={() => score()}>
            <u><label className="mt-5 text-white font-bold text-center bg-gray-800"htmlFor="lname">1. Quel est le nom du système sur lequel est basé Linux ?</label></u>
              <br></br>
              <input className="m-5 text-white text-center bg-gray-800" type="text" id="fname" name="fname"/>
              <br></br>
              <u><label className="mt-5 text-white font-bold text-center bg-gray-800"htmlFor="lname">Quelle est la distribution qui a été développer pour la cyber-sécurité ?</label></u>
              <br></br>
              <input className="m-5 text-white text-center bg-gray-800" type="text" id="lname" name="lname"/>
              <br></br>
              <input className="p-5 bg-gray-600 rounded-lg mb-3" type="submit" value="Submit"/>
            </form> 
            </div>
          </div>  
      </div>
      <div className="bg-gray-800 rounded-lg mt-10 text-[16px]">
      <div className="text-2xl text-center py-10"><u>Credits</u></div>
      <p className="text-white mb-6 text-center">-Alexandre</p>
      <p className="text-white mb-6 text-center">-Issam</p>
      <p className="text-white mb-6 text-center">-Viktor</p>
      </div>
    </main>
  )
}