import React from 'react';
import { Home } from './screens/Home';
import { Projets } from './screens/Projets';
import { Bio } from './screens/Biographie';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { NavBar } from './components/Navigation';
import { Distributions } from './screens/Distributions';



function App() {
  return (
    <html className='h-full w-dvh bg-gray-900 text-white'>
      <meta charSet="utf-8"></meta>
      <body>
      <NavBar/>
        <div className='container mt-10'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/projets' element={<Projets />} />
            <Route path='/bio' element={<Bio />} />
            <Route path='/dist' element={<Distributions />} />
          </Routes>
        </div>
      </body>
    </html>
  );
}


export default App;
