import { motion } from "framer-motion"

export const Bio = () => {

    return(
        <main className="h-screen">
            <div>
                <div className='container flex-col flex p-4 bg-gray-800 rounded-lg'>
                    <div id='linus' className='box-border grid-rows-1 w-full p-6 hover:bg-gray-700'>
                        <div className="flex align-center">
                            <motion.div className="float-left">
                                <img className='w-64' src='images/linus.jpg' alt='linus'></img>
                                <div className='w-64 text-center p-4 font-bold'><a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/Linus_Torvalds" target="_blank">Linus Torvalds</a></div>
                            </motion.div>
                            <motion.div initial={{opacity:0, x:-100}} whileInView={{transition:{delay:0.15,duration:0.5}, opacity:[0,0,0,0,1], scale:1, x:0}} viewport={{once:true}} id="linus" className='w-full px-8 float-left text-left'>
                                <ul>
                                <li>
                                    <strong><u><p>L'inventeur de Linux ?</p></u></strong>
                                </li>
                                <li>
                                    <p>Linus Torvalds, né en 1969 à Helsinki en Finlande, est le créateur du <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/Noyau_Linux" target="_blank">noyau Linux</a>, un noyau de système d'exploitation open source largement utilisé dans le monde entier. Étudiant à l'Université d'Helsinki, il a lancé le projet Linux en 1991, offrant une alternative gratuite et modifiable par tout le monde, à l'inverse des autres noyaux de système d'exploitation comme <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/Berkeley_Software_Distribution" target="_blank">BSD</a> ou <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/XNU" target="_blank">XNU</a>. Son approche collaborative a rapidement attiré des contributeurs du monde entier. 
                                        Aujourd'hui, Linux alimente des serveurs, des appareils mobiles (Avec Android) et bien d'autres systèmes.</p>
                                </li>
                                <br></br>
                                <li>
                                    <p>Torvalds est reconnu pour son style de gestion ouvert et son engagement en faveur de la liberté du logiciel. 
                                        Linus Torvalds demeure une figure emblématique de l'innovation technologique, illustrant le pouvoir de la collaboration et du partage des connaissances dans le domaine de l'informatique.</p>
                                </li>
                                <br></br>
                                <li>
                                <strong><u><p>Linux, un noyau basé sur le système Unix ?</p></u></strong>
                                </li>
                                
                                <br></br>
                                <li>
                                <p>En effet, Linux est un noyau de type UNIX, c'est à dire qu'il se comporte de façon semblable au système UNIX, qui repose sur un interpréteur appelé <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/Shell_Unix" target="_blank">"shell"</a> qui permet d'exécuter des petits utilitaires sur la machine servant donc d'Interface Homme Machine.</p>
                                </li>
                                </ul>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div hidden className='container flex-col flex p-4 bg-gray-800 rounded-lg'>
                    <div id='ubuntu' className='box-border grid-rows-1 w-full p-6 hover:bg-gray-700'>
                        <div className="flex align-center">
                        <motion.div initial={{opacity:0, x:-100}} whileInView={{transition:{delay:0.15,duration:0.5}, opacity:[0,0,0,0,1], scale:1, x:0}} viewport={{once:true}} id="linus" className='w-full px-8 float-left text-left'>
                            <motion.div className="float-right">
                                <img className='w-auto px-10' src='images/ken.jpg' alt='linus'></img>
                                <div className='w-auto text-center p-4 font-bold'><a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/Ken_Thompson" target="_blank">Kenneth Thompson</a></div>
                            </motion.div>
                            
                                <ul>
                                <li>
                                    <strong><u><p>Un des créateurs du noyau Unix ?</p></u></strong>
                                </li>
                                <br></br>
                                <li>
                                    <p>Kenneth Thompson, né en 1943 à La Nouvelle-Orléans, est un informaticien et l'un des pionniers de l'informatique moderne. Il est surtout connu pour avoir grandement participé à la création du <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/Unix" target="_blank">système Unix</a> avec Dennis Ritchie en 1969, alors qu'ils travaillaient chez Bell Labs.</p>
                                </li>
                                <br></br>
                                <li>
                                    <p>Thompson a contribué de manière significative à de nombreux aspects de l'informatique, notamment le développement du <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/B_(langage)" target="_blank">langage de programmation B</a>, précurseur du <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/C_(langage)" target="_blank">langage C</a>.</p>
                                </li>
                                <br></br>
                                <li>
                                    <p>En 1983, Thompson a reçu le prix Turing, considéré comme le « prix Nobel » de l'informatique, pour ses contributions fondamentales au design et à l'implémentation du système d'exploitation Unix.</p>
                                </li>
                                <br></br>
                                <li>
                                    <p>Sa philosophie de conception, axée sur la simplicité et l'efficacité, a profondément influencé le développement des systèmes d'exploitation et des langages de programmation modernes. Thompson est également connu pour son travail sur le langage de programmation <a className="text-blue-500 hover:text-blue-600 hover:font-bold" href="https://fr.wikipedia.org/wiki/Go_(langage)" target="_blank">Go</a>, développé chez Google.</p>
                                </li>
                                </ul>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    )
}