import "../App.css";
import { ReactComponent as GitIcon } from "../Git.svg";
import { ReactComponent as TuxIcon } from "../Tux.svg";
import {
    VerticalTimeline,
    VerticalTimelineElement,
  } from "react-vertical-timeline-component";
  
import "react-vertical-timeline-component/style.min.css";

let timelineElements = [
    {
      id: 1,
      title: "Linux Kernel",
      description:
        "Développé afin de résoudre la faible disponibilité du serveur informatique UNIX de l'université d'Helsinki. Il vise a remplacer Minux un OS qui refuse d'intégrer les contributions visant à l'améliorer.",
      buttonText: "GitHub - Linux",
      date: "Septembre 1991",
      icon: "tux",
      link: "https://github.com/torvalds/linux",
      look: "vertical-timeline-element-title text-yellow-500 font-bold",
    },
    {
        id: 2,
        title: "Git",
        description:
          "Git a été initialement conçu pour gérer le développement du noyau Linux, mais il est rapidement devenu un outil indispensable pour de nombreux autres projets logiciels. Torvalds a commencé à travailler sur Git en 2005, en réponse à des limitations rencontrées avec d'autres systèmes de gestion de versions. Git offre une gestion de versions rapide, efficace et décentralisée, permettant à de multiples développeurs de collaborer sur un projet sans se heurter à des conflits de version.",
        buttonText: "GitHub - Git",
        date: "Avril 2005",
        icon: "git",
        link: "https://github.com/git/git",
        look: "vertical-timeline-element-title text-orange-700 font-bold"
      }
  ];
  
export default timelineElements;



export const Projets = () => {

    let gitIconStyles = { background: "#26AB63" };
    let tuxIconStyles = { background: "#f9c74f" };

    return (
        <div className="h-screen">
            <strong><u><p className="text-center text-3xl">Les projets importants de la vie de Linus Torvalds</p></u></strong><br></br><br></br>
            <div>
            <VerticalTimeline>
                {timelineElements.map((element) => {
                let isGitIcon = element.icon === "git";
                let showButton =
                    element.buttonText !== undefined &&
                    element.buttonText !== null &&
                    element.buttonText !== "";
        
                return (
                    <VerticalTimelineElement 
                    className="vertical-timeline-element--work"
                    key={element.id}
                    date={element.date}
                    dateClassName="date"
                    iconStyle={isGitIcon ? gitIconStyles : tuxIconStyles}
                    icon={isGitIcon ? <GitIcon /> : <TuxIcon />}
                    >
                    <h3 className={element.look}>
                        {element.title}
                    </h3>
                    <p id="description" className="text-black">{element.description}</p>
                    
                    {showButton && (
                        
                        <a
                        className={`button text-blue-500 ${
                            isGitIcon ? "gitButton" : "tuxButton"
                        }`}
                        href={element.link}
                        >
                        {element.buttonText}
                        </a>
                    )}
                    </VerticalTimelineElement>
                );
                })}
            </VerticalTimeline>
            </div>
      
      </div>
      );
    }
